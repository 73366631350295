
// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"

const StyledDownloadsList = styled(Container)`
  max-width: 1140px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 40px;

  h4 {
    font-size: 21px;
    margin-block: 10px;
    border-bottom: 1px solid ${colors.purpleLightBg};
    padding-bottom: 5px;		
    margin-left: 10px;

    ${breakpoint.small`
      margin-left: 20px;
    `}
  }

  & > div {
    padding: 0!important;
  }

  .fullList {
    display: flex!important;
    flex-wrap: wrap;
    gap: 20px 0;

    .list_row .text_column {
      margin: 15px 0 0 0;
    }

    .list_row.visible {
      flex-wrap: wrap;
      padding: 0;
    }

    .list_row p {
      font-size: 16px!important;
      line-height: 1.5rem;
    }

    .list_row .link {
      margin: 5px 0 0 0;
    }

    & > div, & > section {
      width: 100% !important;

    ${breakpoint.small`
      width: 50% !important;
    `}
      
    ${breakpoint.medium`
      width: 33% !important;
    `}

    ${breakpoint.large`
      width: 25% !important;
    `}

    }
    
    .postDate {
      display: none;
    }

    h2 {
      font-size: 19px!important;
      font-weight: 600;
      margin: 0;
    }
  
    h3 {
      margin-bottom: 1rem;
      text-align: center;
      line-height: 1.2em;
      font-size: 27px;
            
      ${breakpoint.small`
        text-align: left;
      `}        

      &::before {
        content: ">>";
        position: relative;
        top: 2px;
        margin-right: 12px;
        letter-spacing: -6px;
        color: ${colors.orange};
        font-size: 30px;
      }
  }

    p {
      font-size: 16px;
      line-height: 1.2em;

      &.links {
        display: none;
      }
    } 

  }

  .block__content h3 {
    margin-bottom: 5px;
  }

.contactUs {
  font-size: 17px;
  margin: 30px 0 0 14px;

  a {
    color: ${colors.orange};
  }
  a:hover {
    color: ${colors.charcoal};
  }
}

`


export default StyledDownloadsList